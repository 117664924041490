export enum CaseDisposition {
  Landlord = 'landlord',
  Tenant = 'tenant',
  Dismissed = 'dismissed',
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace CaseDisposition {
  export function label(value: CaseDisposition) {
    switch (value) {
      case CaseDisposition.Landlord:
        return 'Landlord'
      case CaseDisposition.Tenant:
        return 'Tenant'
      case CaseDisposition.Dismissed:
        return 'Dismissed'
    }
  }
}

export const CASE_DISPOSITION_OPTIONS = [
  { value: CaseDisposition.Landlord, label: CaseDisposition.label(CaseDisposition.Landlord) },
  { value: CaseDisposition.Tenant, label: CaseDisposition.label(CaseDisposition.Tenant) },
  { value: CaseDisposition.Dismissed, label: CaseDisposition.label(CaseDisposition.Dismissed) },
]
