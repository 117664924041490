export enum CaseStipulation {
  FutureRent = 'future_rent',
  ForeclosedRightOfRedemption = 'foreclosed_right_redemption',
  MoneyJudgement = 'money_judgement',
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace CaseStipulation {
  export function label(value: CaseStipulation) {
    switch (value) {
      case CaseStipulation.FutureRent:
        return 'Future Rent'
      case CaseStipulation.ForeclosedRightOfRedemption:
        return 'Foreclosed Right of Redemption'
      case CaseStipulation.MoneyJudgement:
        return 'Money Judgment'
    }
  }
}
